/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_mobile_analytics_app_id": "d28efb4b7b004c72b040e1a83d676fbe",
    "aws_mobile_analytics_app_region": "eu-central-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "d28efb4b7b004c72b040e1a83d676fbe",
            "region": "eu-central-1"
        }
    },
    "Notifications": {
        "Push": {
            "AWSPinpoint": {
                "appId": "d28efb4b7b004c72b040e1a83d676fbe",
                "region": "eu-central-1"
            }
        },
        "Email": {
            "AWSPinpoint": {
                "appId": "d28efb4b7b004c72b040e1a83d676fbe",
                "region": "eu-central-1"
            }
        },
        "SMS": {
            "AWSPinpoint": {
                "appId": "d28efb4b7b004c72b040e1a83d676fbe",
                "region": "eu-central-1"
            }
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://no9l94s42c.execute-api.eu-central-1.amazonaws.com/develop",
            "region": "eu-central-1"
        },
        {
            "name": "restify",
            "endpoint": "https://ip28t9adxa.execute-api.eu-central-1.amazonaws.com/develop",
            "region": "eu-central-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://fzjfyo5hf5bc5ickjyeidgolu4.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-gmmxko2a6jejzowwyrmi5kzxhi",
    "aws_cognito_identity_pool_id": "eu-central-1:13deedf1-6b66-424c-a937-47a55faa2652",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_Z5mMvG0dK",
    "aws_user_pools_web_client_id": "22jkmacptromdlf7k2ju3ius8a",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "aivy-user-content-develop",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
